import { memoOnlyForKeys, useI18n } from '@9troisquarts/wand';
import { css } from '@emotion/css';
import styled from '@emotion/styled'
import React, { useEffect } from 'react';

const environments = ['staging', 'demo'];

const Banner = styled.div`
  display: flex;
  justify-content: center;
  padding: 8px 16px;
  background-color: #ffdf00;
  color: #df0000;
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 100000;
`;

const bodyClass = css`
  .ant-layout-header,
  .ant-layout-sider {
    margin-top: 38px;
  }
  .ant-layout-content {
    margin-top: 120px;
  }
`;

const EnvironmentBanner = ({ environment }) => {
  const { t } = useI18n();

  
  useEffect(() => {
    if (environment && environments.includes(environment)) {
      document.body.classList.add(bodyClass);
      return () => document.body.classList.remove(bodyClass);
    }
  });

  if (!environment || !environments.includes(environment)) return null;
  
  return (
    <Banner className="ntq-environment-banner">
      {t('sentences.environment_banner', { environment })}
    </Banner>
  )
}

export default React.memo(EnvironmentBanner, memoOnlyForKeys(['environment']))
